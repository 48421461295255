import { Klass } from '#backend/lib/transforms'
import Homework from './Homework'

export class EvaluationShengtongRecord {
  id!: number
  exam_no_list!: string[]
  batch_no_list!: string[]
  state!: string
  state_text!: string
  @Klass(Date) created_at!: Date

}

export class EvaluationShengtongRecordInfo extends EvaluationShengtongRecord {
  @Klass(Homework) related_homeworks: Homework[]
  finished_homework_papers_count_mapping: any
}
