import { Klass } from '#backend/lib/transforms'
import  { Big } from 'big.js'
import { Region } from './Region'
import { Site } from './Site'
import { BundleItem } from './BundleItem'

export class Bundle {
  id!: number
  channel!: 'trial' | 'web' | 'agent' | 'event'
  channel_text: string
  discount!: boolean
  max_own_count?: number
  name!: string
  original_point?: number
  original_price?: Big
  payload!: string
  ios_product_id?: string
  point?: number
  price?: Big
  agent_cash!: Big
  recruit_cash!: Big
  site_id!: number
  @Klass(Site) site: Site
  @Klass(Region) region: Region
  @Klass(BundleItem) bundle_items: BundleItem[]
  slogan?: string
  state: 'pending' | 'confirmed' | 'closed'
  state_text: string
  syscode?: string
  token!: string
  type!: string
  visible!: boolean
  @Klass(Date) created_at!: Date
  @Klass(Date) updated_at!: Date
}
