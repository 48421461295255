import { Boolean } from '#backend/lib/transforms'

export default class QuestionCategory {
  id: number
  code: string
  name: string
  group_id: number
  group_name: string
  row_order: number
  @Boolean() hidden: boolean
}
