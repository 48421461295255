import { Klass } from "#backend/lib/transforms"
import { Courseware } from './Courseware'
import { CoursewareFamily } from "./CoursewareFamily"
import { SpeechTextContentInfo } from './SpeechTextContent'

export class SpeechText extends Courseware {
  content_html: string
  precision: number
}

export class SpeechTextInfo extends SpeechText {
  @Klass(CoursewareFamily) family: CoursewareFamily
  @Klass(SpeechTextContentInfo) speech_text_content: SpeechTextContentInfo
}
