import { Klass } from '#backend/lib/transforms'
import { Admin } from './Admin'

export class QuestionRecheckRecord {
  id!: number
  admin!: Admin
  state_text!: string
  question_id!: number
  @Klass(Date) begin_at!: Date | null
  @Klass(Date) end_at!: Date | null
  @Klass(Date) created_at!: Date | null
}
