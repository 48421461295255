import { Klass, Decimal } from '#backend/lib/transforms'
import Big from 'big.js'

export class AgentPointRecord {
  id!: number
  @Decimal() point!: Big
  content!: string

  @Klass(Date) created_at!: Date
}
