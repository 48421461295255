import { Klass, Boolean } from '#backend/lib/transforms'
import { Site } from './Site'
import { School } from './School'
import { Attachment } from './Attachment'
import { Edition } from './Edition'
import { Type } from 'class-transformer'
import { number } from '#backend/components/simple_form/controls'

export class GroupUploadInfo {
  id!: number
  remark!: string
  import_no!: string
  @Boolean() actived!: boolean

  contact_name!: string
  contact_phone!: string
  contact_email!: string
  school_name!: string
  region!: string

  english_edition_name?: string
  match_edition_name?: string
  @Klass(Edition) editions: Edition[]

  @Klass(Attachment) excel_attachment!: Attachment

  @Boolean() need_actived!: Boolean
  @Boolean() personal_name_required!: Boolean
  site_id!: number
  site!: Site

  province_id!: number
  city_id!: number
  district_id!: number

  school_id?: number
  school?: School

  @Klass(number) edition_ids: number[]

  @Klass(Date) created_at!: Date
}
