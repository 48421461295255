import { Klass } from "#backend/lib/transforms"

export class AppleTransactionRecord {
  id: number
  upcode: string
  state: string
  state_text: string

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
