import { Klass } from "#backend/lib/transforms"

export default class Element {
  extra?: any
  style: string[]
  node: string
  type: "block" | "inline"

  @Klass(Element) children?: Element[]
  content?: string
}
