import { Klass } from '#backend/lib/transforms'
import { Admin } from './Admin'

export default class ExamAdjustRecord {
  id: number
  question_id: number
  adjustment_ratio: number
  state_text: string
  @Klass(Date) created_at: Date
  @Klass(Admin) admin: Admin
}
