import { Klass } from '#backend/lib/transforms'

export class Semester {
  id: number
  title: string
  code: string
  term_year: number

  @Klass(Date)
    begin_at: Date

  @Klass(Date)
    end_at: Date
}
