import Student from './Student'
import { Klass } from '#backend/lib/transforms'

export type HomeworkCheckFeedbackCategory = 'recognition' | 'answer'

export default class HomeworkCheckFeedback {
  id: number
  student_id: number
  homework_id: number
  test_paper_id: number
  question_id: number
  answer_sheet_id: number
  category: HomeworkCheckFeedbackCategory
  content: string
  state: string

  reject_reason?: string

  @Klass(Student) student: Student
  @Klass(Date) created_at: Date
}
