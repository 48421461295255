import { Klass } from '#backend/lib/transforms'
import { Clazz } from './Clazz'

export class ClazzDailyActivity {
  id!: number
  city_name!: string
  clazz_id!: number
  clazz_name!: string
  area!: string
  district_name!: string
  finished_homework_papers_count!: number
  grade_name!: string
  province_name!: string
  published_homework_papers_count!: number
  published_workathons_count!: number
  school_name!: string
  @Klass(Date) statistic_on!: Date
  students_count!: number
  @Klass(Date) created_at!: Date

  @Klass(Clazz) clazz!: Clazz
}
