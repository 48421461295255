import { usePageStore } from "#backend/store/page"
import { useSessionStore } from "#backend/store/session"
import { type App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.router
  const page = usePageStore()

  router.beforeEach(async (to: any, from: any, next: any) => {
    try {
      const session = useSessionStore(app.config.globalProperties.$pinia)
      await session.prepare(app.config.globalProperties)

      if (to.meta.auth !== false && session.account == null) {
        next("/login")
      } else {
        next()
      }
    } catch (error) {
      page.errors.push(error as Error)
    }
  })
}
