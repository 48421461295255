import { Klass } from '#backend/lib/transforms'
import { Attachment } from './Attachment'
import { CoursewareContent } from "./CoursewareContent"

export class RepeatTextContent extends CoursewareContent {
}

export class RepeatTextContentInfo extends RepeatTextContent {
  @Klass(Attachment) media_attachment: Attachment
}
