import { Klass } from '#backend/lib/transforms'
import  { Big } from 'big.js'
import { Region } from './Region'
import { Site } from './Site'
import { Jackpot } from './Jackpot'
import { Promoter } from './Promoter'
import { Admin } from './Admin'

export class CouponActiveBatch {
  id!: number
  no_start: string
  no_stop: string
  no_count: number
  site_id!: number
  remark: string
  state: string
  @Klass(Jackpot) jackpot?: Jackpot
  @Klass(Promoter) promoter?: Promoter
  @Klass(Admin) admin: Admin
  @Klass(Site) site: Site
  @Klass(Date) created_at!: Date
  @Klass(Date) expired_on!: Date
}
