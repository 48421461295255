import { Klass } from '#backend/lib/transforms'

export class City {
  id!: number
  name!: string
  code!: string
  remark!: string
  province_id!: number
  province_name!: string
}
