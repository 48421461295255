import { Klass } from '#backend/lib/transforms'
import { Site } from './Site'
import { Region } from './Region'
import { City } from './City'
import { Edition } from './Edition'
import TextbookFolder from './TextbookFolder'

export class RegionInfo extends Region {
  @Klass(Site) site: Site
  @Klass(City) cities: City[]
  @Klass(Edition) editions: Edition[]
  @Klass(TextbookFolder) textbook_folders: TextbookFolder[]
}
