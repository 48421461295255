import { Klass, Boolean } from "#backend/lib/transforms"
import Unit from './Unit'

export default class UnitModule {
  id: number
  schoolbook_id: number
  name: string
  semester: string
  row_order: number

  @Boolean() online: boolean

  @Klass(Unit) units: Unit[]
}
