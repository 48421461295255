import { Klass, ToMap } from '#backend/lib/transforms'
import { AttachmentVersion } from './AttachmentVersion'

export class Attachment {
  id!: number
  catalog?: string
  original_filename!: string
  context: string

  @ToMap(AttachmentVersion) versions!: Map<string, AttachmentVersion>

  getVersion(...keys: string[]): AttachmentVersion {
    for (const key of keys) {
      if (this.versions.has(key)) {
        return this.versions.get(key)!
      }
    }

    throw new Error('No version found')
  }

  original_url(): string {
    return this.getVersion('original').url
  }

  content_type(): string {
    return this.getVersion('original').content_type
  }

  is_audio(): boolean {
    return this.content_type().includes('audio')
  }

  is_video(): boolean {
    return this.content_type().includes('video')
  }

  is_image(): boolean {
    return this.content_type().includes('image')
  }
}
