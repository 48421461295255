import { Klass } from '#backend/lib/transforms'
import UnitText from "./UnitText"

export default class Unit {
  id: number
  unit_module_id: number
  name: string
  fullname: string
  row_order: number
  abbreviation: string
  type: string
  words: string
  tag_list: string[]

  @Klass(UnitText) unit_texts: UnitText[]
}
