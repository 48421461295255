import { Klass } from '#backend/lib/transforms'

export class Role {
  id: number
  code: string
  code_text: string
  level: string
  level_value: number

  @Klass(Date)
    start_at: Date

  @Klass(Date)
    end_at: Date
}
