import { Boolean, Klass } from '#backend/lib/transforms'
import { CoursewareFamily } from "./CoursewareFamily"
import Textbook from './Textbook'

export class Courseware {
  id: number
  code: string
  estimate_time: number
  family_name: string
  formatted_content_raw: string
  grouping: string
  media_type: string
  name: string
  name_with_code: string
  partition_name: string
  scanner_papers_count: number
  scanner_template_code: string
  standard: string
  state: string
  state_text: string
  student_badges: string[]
  student_ticket_code: string
  subhead: string
  suggested_schoolbook_id: number
  tag_list: string[] = []
  teacher_badges: string[]
  teacher_ticket_code: string
  textbook_id: number
  textbook_name: string
  token: string
  total_score: number
  unit_text_code: string
  type: string
  memo: string
  discard_reason: string
  messages: string[]
  subject_id: number
  family_id: number
  content_id: number
  creator_id: number
  creator_name: string

  @Boolean() shared: Boolean | boolean
  @Boolean() include_manual_check: Boolean | boolean
  @Boolean() override_ticket_enabled: Boolean | boolean
  @Klass(Date) checked_at: Date
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date

  convertedType() {
    switch (this.type) {
      case 'ListenText':
        return 'listen_texts'
      case 'RepeatText':
        return 'repeat_texts'
      case 'SpeechText':
        return 'speech_texts'
      case 'TestPaper':
        return 'test_papers'
      case 'InteractiveReading':
        return 'interactive_readings'
      case 'Cosplay':
        return 'cosplays'
    }
  }

  isPending() {
    return this.state == "pending"
  }

  isOnline() {
    return this.state == "online"
  }

  isOffline() {
    return this.state == "offline"
  }

  isDiscard() {
    return this.state == "discard"
  }
}

export class CoursewareInfo extends Courseware {
  @Klass(Textbook) textbook: Textbook
  @Klass(CoursewareFamily) family: CoursewareFamily
}
