import { Klass } from '#backend/lib/transforms'
import { Subject, School, Clazz } from "../models"

export class SchoolReport {
  id: number
  school_id: number
  subject_id: number

  result: string[]

  @Klass(Date) start_on: Date
  @Klass(Date) end_on: Date
}

export class SchoolReportInfo extends SchoolReport {
  @Klass(Subject) subject: Subject
  @Klass(School) school: School
  @Klass(Clazz) clazzes: Clazz[]
}
