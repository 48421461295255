import { Boolean } from '#backend/lib/transforms'
import { EntityRepo, EntityIndex } from './EntityRepo'

export default class Knowledge {
  id: number
  name: string
  code: string
  subject_id: number
  ancestry: string | null
  ancestry_depth: number
  parent_id: number

  @Boolean() collectable: boolean
  @Boolean() pointable: boolean
  @Boolean() visible: boolean

  get ancestry_ids() { return (this.ancestry ?? "").split('/').map((it) => parseInt(it)) }

  get name_with_code() { return this.code + "-" + this.name }

  isAncestry(id: number) {
    const ancestry_ids = (this.ancestry ?? "").split('/')
    return ancestry_ids.includes(id.toString())
  }
}

export class KnowledgeRepo extends EntityRepo<Knowledge> {
  parent_id = new EntityIndex<number | null, Knowledge>(it => it.parent_id)

  override buildIndex(entity: Knowledge): void {
    this.parent_id.add(entity)
  }
}
