import { Klass } from '#backend/lib/transforms'
import { Big } from 'big.js'
import { Site } from './Site'
import { Bundle } from './Bundle'
import { Clazz } from './Clazz'
import { School } from './School'
import { Agent } from './Agent'
import Student from './Student'

export class Order {
  id!: number

  no: string
  state: 'pending' | 'paid' | 'finished' | 'refunded' | 'expired' | 'transfered'
  status_text: string
  adjust_price: Big
  agent_cash: Big
  recruit_cash: Big
  total_price: Big
  total_point: Big
  quantity: number

  subject: string
  user_name?: string
  user_phone?: string
  user_alipay?: string

  subtitle?: string
  memo?: string

  @Klass(Site) site: Site
  @Klass(Bundle) bundle: Bundle

  @Klass(Clazz) clazz?: Clazz
  @Klass(School) school?: School
  @Klass(Student) user: Student
  @Klass(Agent) agent?: Agent

  @Klass(Date) deliver_at?: Date
  @Klass(Date) refunded_at?: Date

  @Klass(Date) created_at: Date
}
