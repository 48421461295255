import { Klass } from "#backend/lib/transforms"
import Workathon from "./Workathon"

export default class Homework {
  id: number
  workathon_id: number
  ownership: string
  standard: string
  exam_time: any
  allow_display_correct: boolean
  allow_published_score: string[]
  ticket_code: string
  courseware_id!: number
  homework_papers_count!: number
  courseware_name!: string

  @Klass(Workathon) workathon!: Workathon
}
