import { Klass } from "#backend/lib/transforms"
import { Courseware } from './Courseware'
import { RepeatTextContentInfo } from './RepeatTextContent'

export class RepeatText extends Courseware {
  content_html: string
  precision: number
}

export class RepeatTextInfo extends RepeatText {
  @Klass(RepeatTextContentInfo) repeat_text_content: RepeatTextContentInfo
}
