import { Boolean, Klass } from "#backend/lib/transforms"
import _ from "lodash"
import { Attachment } from './Attachment'
import Element from "./Element"
import QuestionCategory from "./QuestionCategory"
import QuestionInput from "./QuestionInput"
import QuestionOption from "./QuestionOption"
import { Tag } from "./Tag"
import QuestionKnowledgeable from "./QuestionKnowledgeable"
import { Question } from './client/Question'
import { UserInput } from "./client/UserInput"

export class ManualCheckOption {
  title: string
  rate: number
  row_order: number
}

export class QuestionStatistic {
  avg_accuracy: number
  times: number
  total_point: number
  score_point: number
}

export default class QuestionBox {
  @Klass(Question) question: Question

  ai_prompt_code: string
  subject_id: number
  copyright: string
  explan_media_type: "none" | "audio" | "video"
  explan_html: string
  knowledge_text: []
  shengtong_open_type: string
  column: number
  content: string
  solution: string
  explan: string
  check_rule: string
  recording_begin_beep: string
  manual_check_explain: string

  @Klass(ManualCheckOption) manual_check_options: ManualCheckOption[]

  @Boolean() collectable: boolean
  @Boolean() primary: boolean
  @Klass(Tag) tags?: Tag[]
  @Klass(Date) created_at: Date

  @Klass(QuestionCategory) category?: QuestionCategory
  @Klass(QuestionInput) inputs?: QuestionInput[]
  @Klass(QuestionKnowledgeable) knowledgeables?: QuestionKnowledgeable[]
  @Klass(QuestionOption) options?: QuestionOption[]

  @Klass(Attachment) explan_media_attachment?: Attachment
  @Klass(Attachment) begin_media_attachment?: Attachment
  @Klass(Attachment) finish_media_attachment?: Attachment

  @Klass(QuestionStatistic) statistic?: QuestionStatistic

  need_recording(): boolean {
    return this.question.type === "dialog" || this.question.type === "speech" || this.question.type === "repeat" || this.question.type === "shengtong_open"
  }

  convert_to_markdown(): string {
    const result = []
    const sorted_inputs = _.sortBy(this.inputs, 'row_order')
    const sorted_options = _.sortBy(this.options, 'row_order')

    result.push(`<question type="关联" ref="${this.question.id}" no="${this.question.suite_position}" score="${sorted_inputs?.length}">`)
    _.split(this.content, "\n").forEach((content) => result.push(`  ${content}`))

    sorted_options?.forEach((option) => {
      result.push(`<option value="${option.value}">${_.trim(option.content)}</option>`)
    })

    const display_answers_text: string[] = []
    const correct_answers_text: string[] = []
    sorted_inputs?.forEach((input, index) => {
      if (input.override_display_answers_text) {
        display_answers_text.push(`${index + 1}.${input.override_display_answers_text}`)
      }

      correct_answers_text.push(`${index + 1}.${input.answers_text}`)
    })
    result.push(`显示答案: ${display_answers_text.join(",")}`)
    result.push(`正确答案: ${correct_answers_text.join(",")}`)
    result.push(`</question>`)

    return result.join("\n")
  }

  convert_to_user_inputs() {
    return this.inputs?.map((input) => {
      const user_input = new UserInput()
      user_input.question_id = input.question_id
      user_input.question_input_id = input.id
      user_input.value = _.get(input.correct_answers, '0')
      user_input.state = "pending"
      user_input.formatted_display_answers = input.formatted_display_answers

      return user_input
    }) ?? []
  }
}
