import { Klass } from '#backend/lib/transforms'
import { City, Site, JackpotBundleRelation } from '#backend/models'

export class Jackpot {
  id!: number
  name: string
  status: string
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date

  is_online() {
    return this.status == 'online'
  }

  is_offline() {
    return this.status == 'offline'
  }
}

export class JackpotInfo extends Jackpot {
  @Klass(Site) site: Site
  @Klass(City) city?: City
  jackpot_bundle_relations: JackpotBundleRelation[]
}
