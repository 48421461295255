import { Klass } from "#backend/lib/transforms"
import Big from "big.js"
import { Courseware } from './Courseware'
import { CoursewareFamily } from "./CoursewareFamily"
import Question from './Question'
import Suite from './Suite'
import { TestPaperContentInfo } from "./TestPaperContent"
import TestPaperEntry from './TestPaperEntry'
import { TestPaperPartition } from './TestPaperPartition'
import Textbook from "./Textbook"

export class TestPaper extends Courseware {
  unit_text_uid: string
  creator_usercode: string
  override_ticket_code: string
}

export class TestPaperInfo extends TestPaper {
  @Klass(Textbook) textbook: Textbook
  @Klass(CoursewareFamily) family: CoursewareFamily

  @Klass(TestPaperContentInfo) test_paper_content: TestPaperContentInfo

  @Klass(TestPaperPartition)partitions: TestPaperPartition[]
  @Klass(TestPaperEntry) entries: TestPaperEntry[]
  @Klass(Suite) suites: Suite[]
  @Klass(Question) questions: Question[]

  fetch_question_input_score(question_input_id: number) {
    const question = this.questions.find(q => q.inputs?.some(i => i.id === question_input_id))
    if (question == undefined) {
      return null
    }

    const test_paper_entry = this.entries.find(e => e.question_id === question.id)
    const question_score = test_paper_entry?.question_score
    const total_weight = question.inputs?.reduce((acc, input) => acc.plus(input.weight), new Big(0)) ?? 0
    const question_input = question.inputs?.find(i => i.id === question_input_id)
    return question_score?.times(question_input?.weight ?? 0).div(total_weight)
  }
}
