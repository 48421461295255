import { Klass, Boolean } from '#backend/lib/transforms'
import Element from "./Element"
import QuestionInput from "./QuestionInput"
import QuestionOption from "./QuestionOption"
import QuestionCategory from "./QuestionCategory"
import { Attachment } from './Attachment'

export default class Question {
  id: number
  ai_prompt_code: string
  subject_id: number
  content: string
  explan_html: string
  copyright: string
  instruction: string
  manual_check_options: any[]
  manual_check: boolean
  suite_id: number
  suite_position: number
  row_order: number
  type: "select" | "input" | "char" | "boolean" | "text" | "dialog" | "speech" | "repeat" | "shengtong_open" | "photo"
  explan_media_type: "none" | "audio" | "video"
  knowledge_ids: number[]
  knowledgeable_ids: number[]
  score_rule: string

  @Klass(Attachment) explan_media_info?: Attachment
  @Klass(Attachment) begin_media_info?: Attachment
  @Klass(Attachment) finish_media_info?: Attachment

  @Klass(Date) created_at!: Date
  @Boolean() collectable: boolean
  @Boolean() primary: boolean
  @Klass(QuestionCategory) category?: QuestionCategory
  @Klass(Element) formatted_content: Element[]
  @Klass(QuestionOption) options?: QuestionOption[]
  @Klass(QuestionInput) inputs?: QuestionInput[]
}
