import { Klass } from "#backend/lib/transforms"
import { CosplayContentInfo } from './CosplayContent'
import { Courseware } from './Courseware'

export class Cosplay extends Courseware {
  content_html: string
  precision: number
}

export class CosplayInfo extends Cosplay {
  @Klass(CosplayContentInfo) cosplay_content: CosplayContentInfo
}
