import { Klass } from '#backend/lib/transforms'
import * as t from '#backend/lib/transforms'
import Pagination from '$commons/lib/Pagination'

export class Product {
  id!: number
  name: string
  grouping: string | null
  ticket_codes: string[]
  notification_description: string
  notification_enabled: boolean
  site_id: number

  @Klass(Date) updated_at: Date
  @Klass(Date) created_at: Date
}

export class ProductStat {
  assortment_id: number
  grouping: string
  count: number
}

export class ProductPage extends Pagination<Product> {
  @t.Klass(Product) list: Product[] = []
  @t.Klass(ProductStat) stats: ProductStat[] = []
}
