import { Klass } from '#backend/lib/transforms'
import { Exportation } from './Exportation'

export class UsageReport {
  school_id: number
  subject_id: number
  source: string
  semester_ids: number[]
  grade_ids: number[]
  clazz_ids: number[]

  @Klass(Date) start_date: Date
  @Klass(Date) end_date: Date

  @Klass(Exportation) exportation: Exportation
}
