import { Klass, Decimal } from '#backend/lib/transforms'
import Big from 'big.js'
import Element from "./Element"

export default class QuestionOption {
  id: number
  value: string
  content: string
  content_html: string
  question_id: number
  row_order: number

  @Decimal() percentage: Big

  @Klass(Element) formatted_content: Element[]
}
