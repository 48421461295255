import axios, { Axios } from "axios"
import { App } from 'vue'

const $axios = axios.create({
  timeout: 60000,
})

export default function(app: App) {
  app.config.globalProperties.$axios = $axios
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: typeof $axios
  }
}
