import { Klass } from '#backend/lib/transforms'

export class PointRecord {
  id!: number
  point!: number
  event_type_text: string
  event_param: string
  description: string

  @Klass(Date) created_at: Date
}
