import { Klass } from '#backend/lib/transforms'
import  { Big } from 'big.js'
import { Region } from './Region'
import { Site } from './Site'
import { Jackpot } from './Jackpot'
import { Promoter } from './Promoter'
import { Admin } from './Admin'

export class SchoolFeatureRecord {
  id: number
  remark: string
  state: string
  state_text: string
  features: object
  @Klass(Admin) creator: Admin
  @Klass(Date) created_at: Date
  @Klass(Date) expired_on: Date
  @Klass(Date) start_on: Date
}
