import { Klass } from '#backend/lib/transforms'
import { Attachment } from './Attachment'
import { CoursewareContent } from "./CoursewareContent"
import { CosplayRole } from './CosplayRole'

export class CosplayContent extends CoursewareContent {
}

export class CosplayContentInfo extends CosplayContent {
  @Klass(Attachment) video_attachment: Attachment
  @Klass(Attachment) dialog_attachment: Attachment
  @Klass(CosplayRole) roles: CosplayRole[]
}
