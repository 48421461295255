import { Klass, Boolean } from '#backend/lib/transforms'
import { Attachment } from './Attachment'

export class Subject {
  id: number
  name: string
  code: string
  @Boolean() online: boolean
  description: string
  row_order: number
  cover_url: string

  @Klass(Attachment) cover_attachment!: Attachment
}
