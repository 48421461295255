import { Klass, Boolean } from '#backend/lib/transforms'
import Element from './Element'

export default class Suite {
  id: number
  subject_id: number
  estimate_time: number
  content: string
  content_html: string
  media_source: string
  media_source_html: string
  media_type: string
  media_duration: number
  type: string

  @Boolean() hide: boolean

  @Klass(Element) formatted_content: Element[]
}
