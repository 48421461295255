import { Klass } from "#backend/lib/transforms"
import { Schoolbook } from "./Schoolbook"
import { School } from "./School"
import Unit from "./Unit"
import UnitModule from "./UnitModule"
import { Teacher } from "./Teacher"
import { LessonPlan } from "./LessonPlan"
import { LessonAttachment } from './LessonAttachment'

export class LessonPlanInfo extends LessonPlan {
  @Klass(Schoolbook) schoolbook: Schoolbook
  @Klass(School) school: School
  @Klass(Unit) unit: Unit
  @Klass(UnitModule) unit_module: UnitModule
  @Klass(Teacher) teacher: Teacher
  @Klass(LessonAttachment) lesson_attachments: LessonAttachment[]
}
