import { Boolean } from '#backend/lib/transforms'
import { EntityRepo } from './EntityRepo'
import { Klass } from "#backend/lib/transforms"
import { Attachment } from './Attachment'
import { Grade } from './Grade'

export class Schoolbook {
  id: number
  fullname: string
  semester: 'first' | 'second'
  volume_name: string
  series: string
  short_name: string
  grade_id: number
  edition_id: number
  description: string
  keywords: string
  subject_id: number
  knowledge_ids: number[]
  question_category_ids: number[]

  @Boolean() online: boolean
}

export class SchoolbookRepo extends EntityRepo<Schoolbook> {
  override buildIndex(entity: Schoolbook): void {
    this.id.add(entity)
  }
}

export class SchoolbookInfo extends Schoolbook {
  @Klass(Attachment) cover_attachment: Attachment
  @Klass(Grade) grade: Grade
}
