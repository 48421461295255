import { Klass } from '#backend/lib/transforms'

export class Teleporter {
  id: number
  coupon_code: string
  site_id: number
  textbook_code: string
  title: string
  token: string

  @Klass(Date) expired_at: Date
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
