import { Klass } from '#backend/lib/transforms'
import Textbook from './Textbook'
import { Schoolbook } from './Schoolbook'

export class ExaminationTextbook {
  id!: number
  examination_id!: number
  @Klass(Textbook) textbook: Textbook
  @Klass(Schoolbook) schoolbook: Schoolbook
}
