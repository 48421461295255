import { Klass } from '#backend/lib/transforms'
import { Grade, Bundle } from '#backend/models'

export class JackpotBundleRelation {
  id: number
  related_code: string

  @Klass(Grade) grade: Grade
  @Klass(Bundle) bundle: Bundle
}
