import { Klass } from '#backend/lib/transforms'
import { School } from './School'
import Teacher from './Student'
import { Clazz } from './Clazz'
import { City } from './City'
import Subscription from './Subscription'
import Teachership from './Teachership'

export default class TeacherInfo extends Teacher {
  @Klass(School) school: School | null
  @Klass(City) city: City | null
  @Klass(Clazz) clazzes: Clazz[]
  @Klass(Subscription) subscriptions: Subscription[]
  @Klass(Teachership) teacherships: Teachership[]
}
