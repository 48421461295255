import { Klass } from '#backend/lib/transforms'
import { School } from './School'
import { Agent } from './Agent'

export class SchoolIncomeStatistic {
  id!: number
  @Klass(Date) paid_date!: Date

  personal_paid_amount!: number
  personal_paid_agent_cash!: number

  personal_refunded_amount!: number
  personal_refunded_agent_cash!: number

  agent_paid_amount!: number
  agent_refunded_amount!: number

  agent_paid_point!: number
  agent_refunded_point!: number

  @Klass(School) school!: School
  @Klass(Agent) agent?: Agent
}
