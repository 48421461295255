import { Klass } from '#backend/lib/transforms'
import { School } from './School'
import { Admin } from './Admin'
import { Bundle } from './Bundle'
import { Clazz } from './Clazz'

export class MembershipBatchRecord {
  id!: number
  state!: 'pending' | 'processing' | 'error' | 'approved' | 'rejected'
  state_text: string
  message!: string
  remark!: string
  reason!: string
  quantity!: number

  @Klass(Date) created_at!: Date
  @Klass(School) school!: School
  @Klass(Admin) creator!: Admin
  @Klass(Bundle) bundle!: Bundle
  @Klass(Clazz) clazzes!: Clazz[]
}
