import { Klass } from '#backend/lib/transforms'
import AnswerSheet from './AnswerSheet'
import { Courseware } from './Courseware'
import Student from './Student'

export default class ExamParsedRecord {
  id!: number
  answer_sheet_id: number
  exam_plan_id: number
  examination_parsing_record_id: number
  homework_id: number
  student_id: number
  @Klass(Student) student: Student
  @Klass(AnswerSheet) answer_sheet: AnswerSheet
  @Klass(Courseware) courseware: Courseware
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
