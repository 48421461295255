import { Klass } from '#backend/lib/transforms'
import { Admin } from './Admin'

export class Activity {
  id!: number
  owner_id!: number
  owner_type!: string
  owner_name?: string

  action?: string

  trackable_id!: number
  trackable_type!: string
  trackable_info!: string

  recipient_id: number
  recipient_type: string

  details: string[]

  @Klass(Date) created_at: Date
}
