import { Klass } from '#backend/lib/transforms'
import { Clazz } from './Clazz'

export default class Workathon {
  id: number
  batch_no: string
  exam_no: string
  assigner_name: string
  clazz_id: number
  clazz_fullname: string
  published_on: string
  homework_papers_count: number
  workathoners_count: number
  subject_id: number
  stage: string
  stage_text: string
  remark: string

  @Klass(Clazz) clazz!: Clazz
  @Klass(Date) published_at: Date
  @Klass(Date) archived_at: Date
  @Klass(Date) deadline_at: Date
  @Klass(Date) created_at: Date
}
