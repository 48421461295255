import { Decimal, Klass } from "#backend/lib/transforms"
import Homework from './Homework'
import Big from "big.js"

export class HomeworkPaper {
  id: number
  unrevised_count: number
  overdue: string
  submission_count: number
  spent_time: number
  @Decimal() ranking_point: Big
  @Decimal() total_score: Big
  @Decimal() score: Big
  accuracy: number
  state: "pending" | "started" | "rejected" | "submitted" | "processed" | "checked" | "confirmed"

  @Klass(Date) submitted_at?: Date
  @Klass(Date) confirmed_at?: Date
  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date

  isFinished() {
    return [ "submitted", "processed", "checked", "confirmed" ].includes(this.state)
  }
}


export class HomeworkPaperInfo extends HomeworkPaper {
  @Klass(Homework) homework: Homework
}
