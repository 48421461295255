import { Klass } from '#backend/lib/transforms'
import { Attachment } from './Attachment'
import { Schoolbook } from './Schoolbook'
import Textbook from './Textbook'
import TextbookFolder from './TextbookFolder'

export default class TextbookInfo extends Textbook {
  @Klass(TextbookFolder) folder: TextbookFolder
  @Klass(Attachment) cover_attachment: Attachment
  @Klass(Schoolbook) schoolbooks: Schoolbook[]
  schoolbook_ids: number[]
}
