import { Klass } from '#backend/lib/transforms'
import { Clazz, Teacher, Subject } from '../models'
import { EntityRepo, EntityIndex } from './EntityRepo'

export default class Teachership {
  id!: number
  clazz_id: number
  leaved_for_clazz!: boolean
  role_text: string
  subject_id: number
  @Klass(Date) joined_at!: Date
  @Klass(Date) leave_at!: Date
  @Klass(Clazz) clazz!: Clazz
}

export class TeachershipRepo extends EntityRepo<Teachership> {
  clazz_parent_id = new EntityIndex<number | null, Teachership>(it => it.clazz.parent_id)

  override buildIndex(entity: Teachership): void {
    this.id.add(entity)
    this.clazz_parent_id.add(entity)
  }
}

export class TeachershipInfo extends Teachership {
  @Klass(Teacher) teacher!: Teacher
  @Klass(Subject) subject!: Subject
}
