import { createApp } from 'vue'
import AppTemplate from './AppTemplate.vue'

import './assets/style.css'

import '$commons/vendor/fontawesome'

import '#legacy/coursewares'

import './vendor/copy'
import './vendor/easymde'
import './vendor/selectize'
import './assets/stylesheets'

const app = createApp(AppTemplate)

import * as initializers from './initializers'
for (const key in initializers) {
  initializers[key](app)
}

app.mount('#app')
