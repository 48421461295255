import { Klass } from "#backend/lib/transforms"
import { Agent } from "./Agent"

export class AgentTransactionRecord {
  id: number
  token: string
  state: string
  state_text: string

  @Klass(Agent) agent: Agent

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
