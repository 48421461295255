import { Klass } from '#backend/lib/transforms'
import { Knowledge } from '#backend/models'

export default class QuestionKnowledgeable {
  id: number
  knowledge_id: number
  question_id: number
  point: string
  row_order: number

  @Klass(Knowledge) knowledge: Knowledge
}
