import { Klass, Decimal } from '#backend/lib/transforms'
import Big from 'big.js'

export class AgentBalanceRecord {
  id!: number
  @Decimal() amount!: Big
  content!: string

  @Klass(Date) created_at!: Date
}
