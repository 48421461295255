import { Klass } from '#backend/lib/transforms'
import { Clazz, Student } from '../models'
import { EntityRepo, EntityIndex } from './EntityRepo'

export default class Studentship {
  id!: number
  leaved_for_clazz!: boolean
  number: string
  @Klass(Date) joined_at!: Date
  @Klass(Date) leave_at!: Date
  @Klass(Clazz) clazz!: Clazz
}

export class StudentshipRepo extends EntityRepo<Studentship> {
  clazz_parent_id = new EntityIndex<number | null, Studentship>(it => it.clazz.parent_id)

  override buildIndex(entity: Studentship): void {
    this.id.add(entity)
    this.clazz_parent_id.add(entity)
  }
}

export class StudentshipInfo extends Studentship {
  @Klass(Student) student!: Student
}
